<template>
  <div class="m-3 p-2 wrapper wrapper--default">
    <h3 class="text-center">Bestand uploaden</h3>
    <div class="flex _column w-50">
      <label for="display_name">Bestand naam:</label>
      <input type="text" name="display_name" class="form-control label" v-model="display_name" />
      <div class="m2">
        <div class="bold">
          Wie mag deze file zien? (bij geen keuze mag iedereen de file zien)
        </div>
        <div class="employee_grid">
          <Checkbox id="roles" v-for="(item, key) in roles" :key="key" :value="item.value" :label="item.text" />
        </div>
      </div>
      <label for="category">Onder welke categorie valt het bestand?</label>
      <select name="category" id="category" v-model="category">
        <option value="Algemene voorwaarden">Algemene voorwaarden</option>
        <option value="Huishoudelijk">Huishoudelijk</option>
        <option value="Compliance">Compliance</option>
        <option value="Verkoop T&T">Verkoop T&T</option>
        <option value="Verkoop Vans">Verkoop Vans</option>
        <option value="Douane">Douane</option>
        <option value="KVK">KVK</option>
        <option value="ICT">ICT</option>
        <option value="Overig" selected>Overig</option>
      </select>
      <input id="image-file" type="file" class="m-2" />
      <button @click="getData" id="uploadButton">Bestand uploaden</button>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/Checkbox.vue";
import hasIndex from "@/functions/hasIndex";

export default {
  components: { Checkbox },
  data: () => ({
    roles: [
      { text: "Extern", value: "extern" },
      { text: "Management", value: "management" },
      { text: "Inkopers", value: "inkopers" },
      { text: "Marketing", value: "marketing" },
      { text: "Werkplaats", value: "werkplaats" },
      { text: "Terrein", value: "terrein" },
      { text: "Delivery", value: "delivery" },
      { text: "Secretariaat", value: "secretariaat" },
      { text: "Verkoper Vans", value: "verkoper_vans" },
      { text: "Verkoper Trucks", value: "verkoper_trucks" },
      { text: "COMAD", value: "comad" },
      { text: "HR", value: "hr" },
      { text: "IT", value: "it" },
      { text: "Telefoniste", value: "telefoniste" },
      { text: "KCC", value: "kcc" },
      { text: "FINAD", value: "finad" },
      { text: "Binnenkomst", value: "binnenkomst" },
      { text: "Transport", value: "transport" },
    ],
    display_name: null,
    category: null,
    employee_roles: [],
  }),
  created() { },
  methods: {
    getData() {
      var input = document.getElementById("image-file");
      if (this.display_name && input && this.category) {
        document.querySelector("#uploadButton").disabled = true;
        const roles = document.getElementsByName("roles");
        this.employee_roles = [];
        for (var j = 0; j < roles.length; j++) {
          if (roles[j].checked) {
            this[roles[j].value] = 1;
            this.employee_roles.push(roles[j].value);
          }
        }

        console.log(document.getElementById('category').value, this.category)

        var headers = new Headers();
        headers.append("KLEYN-APIKEY", process.env.VUE_APP_APIKEY);

        var data = new FormData();
        data.append("file", input.files[0]);
        data.append("permissions", this.employee_roles);
        data.append("displayname", this.display_name);
        data.append("category", this.category);

        var requestOptions = {
          method: "POST",
          headers: headers,
          body: data,
        };

        fetch(`${process.env.VUE_APP_API_URL}upload-file`, requestOptions)
          .then((response) => response.json())
          .then((responseJson) => {
            if (hasIndex(responseJson.message, "geupload")) {
              this.employee_roles = null;
              this.display_name = null;
              input.value = null;
              document.querySelector("#uploadButton").disabled = false;
              this.$store.dispatch("addNotification", {
                message: responseJson.message,
                type: "success",
              });
            } else {
              this.submitted = false;
              document.querySelector("#uploadButton").disabled = false;
              this.$store.dispatch("addNotification", {
                message: responseJson.error,
                type: "danger",
              });
            }
          });
      } else {
        this.$store.dispatch("addNotification", {
          message: "Geen file, Categorie of bestandsnaam ingevuld",
          type: "danger",
        });
      }
    },
  },
};
</script>
